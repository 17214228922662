<script>
import "@vueform/multiselect/themes/default.css";

import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";

export default {
  page: {
    title: "Setting",
    meta: [{
      name: "description",
      content: appConfig.description
    }],
  },
  data() {
    return {
      title: "Setting",
      items: [{
        text: "Velzon",
        href: "/",
      },
      {
        text: "Setting",
        active: true,
      },
      ],
      value: ['javascript'],
      date: null,
    };
  },
  components: {
    Layout,
  }
};
</script>

<template>
  <Layout>
    <div class="profile-foreground position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg">
        <div class="text-center mt-sm-5 mb-4 text-white-50">
          <div>
            <router-link to="/" class="d-inline-block auth-logo mb-4">
              <img src="@/assets/images/logos/ApoioPreto.png" alt="" height="100">
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-4 mb-4 mb-lg-3 pb-lg-4">
      <div class="row g-4">
        <div class="col-auto">
          <div class="avatar-lg">
            <img src="@/assets/images/users/avatar-1.jpg" alt="user-img" class="img-thumbnail rounded-circle" />
          </div>
        </div>
        <div class="col">
          <div class="p-2">
            <h3 class="text-white mb-1">Anna Adame</h3>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div>
          <div class="d-flex">
            <ul class="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
              <li class="nav-item">
                <a class="nav-link fs-14 active" data-bs-toggle="tab" href="#info-tab" role="tab">
                  <i class="ri-airplay-fill d-inline-block d-md-none" />
                  <span class="d-none d-md-inline-block">Informações Pessoais</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link fs-14" data-bs-toggle="tab" href="#password" role="tab">
                  <i class="ri-list-unordered d-inline-block d-md-none" />
                  <span class="d-none d-md-inline-block">Alterar Senha</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link fs-14" data-bs-toggle="tab" href="#programs-tab" role="tab">
                  <i class="ri-airplay-fill d-inline-block d-md-none" />
                  <span class="d-none d-md-inline-block">Programas</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link fs-14" data-bs-toggle="tab" href="#funcionalities" role="tab">
                  <i class="ri-list-unordered d-inline-block d-md-none" />
                  <span class="d-none d-md-inline-block">Funcionalidades</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link fs-14" data-bs-toggle="tab" href="#groups" role="tab">
                  <i class="ri-price-tag-line d-inline-block d-md-none" />
                  <span class="d-none d-md-inline-block">Grupos</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link fs-14" data-bs-toggle="tab" href="#company" role="tab">
                  <i class="ri-folder-4-line d-inline-block d-md-none" />
                  <span class="d-none d-md-inline-block">Empresas</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link fs-14" data-bs-toggle="tab" href="#section" role="tab">
                  <i class="ri-folder-4-line d-inline-block d-md-none" />
                  <span class="d-none d-md-inline-block">Sessões</span>
                </a>
              </li>
            </ul>
            <div class="flex-shrink-0">
              <b-btn class="btn btn-success">
                Salvar Alterações
              </b-btn>
            </div>
          </div>
          <div class="tab-content pt-4 text-muted">
            <div class="tab-pane active" id="info-tab" role="tabpanel">
              <div class="row">
                <div class="col-xxl-12">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title mb-3">Editar Informações</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="password" role="tabpanel">
              <div class="row">
                <div class="col-xxl-12">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title mb-3">Alterar Senha</h5>
                      <form action="javascript:void(0);">
                        <div class="row g-2">
                          <div class="col-lg-4">
                            <label for="oldpasswordInput" class="form-label">
                              Senha Antiga
                              <span class="text-danger">*</span>
                            </label>
                            <input type="password" class="form-control" id="oldpasswordInput"
                              placeholder="Enter current password" />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="programs-tab" role="tabpanel">
              <div class="row">
                <div class="col-xxl-12">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title mb-3">Programas</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="funcionalities" role="tabpanel">
              <div class="row">
                <div class="col-xxl-12">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title mb-3">Funcionalidades</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="groups" role="tabpanel">
              <div class="row">
                <div class="col-xxl-12">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title mb-3">Grupos</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="company" role="tabpanel">
              <div class="row">
                <div class="col-xxl-12">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title mb-3">Empresas</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="section" role="tabpanel">
            <div class="row">
              <div class="col-xxl-12">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title mb-3">Sessões</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>